/* eslint-disable react/prop-types */

import React, { useContext } from "react";
import { graphql } from "gatsby";
import Footer from "~components/Footer.jsx";
import Layout from "~components/Layout.jsx";
import PageBuilder from "~components/cms/PageBuilder.jsx";
import SEO from "~components/SEO.jsx";

const Page = ({ data, location }) => {
  // ===========================================================================
  // variables

  const cms = data.sanityPage;

  const { pagebuilder } = cms;

  // ===========================================================================
  // render

  return (
    <>
      <SEO
        customTitle={cms?.title}
        customDescription={cms?.seoDescription}
        customKeywords={cms?.seoKeywords}
        path={location.pathname}
      />

      <Layout>
        {pagebuilder && <PageBuilder pagebuilder={pagebuilder} />}
      </Layout>

      <Footer />
    </>
  );
};

export default Page;

export const query = graphql`
  query Page($id: String!) {
    sanityPage(id: { eq: $id }) {
      title
      seoDescription
      seoKeywords

      pagebuilder {
        sections {
          ... on SanityCenterButtonSection {
            _type
            buttonLink {
              buttonTarget
              buttonText
            }
          }

          #

          ... on SanityCollectionSection {
            _type
            fontColor {
              title
              hex
            }

            shopifyCollection {
              title
            }
          }

          #

          ... on SanityFloatingImageSection {
            _type
            backgroundColor {
              hex
              title
            }

            imageLeft {
              altText

              asset {
                originalFilename
                title
                description

                fluid {
                  ...GatsbySanityImageFluid_withWebp_noBase64
                  src
                }
              }
            }

            imageRight {
              altText

              asset {
                originalFilename
                title
                description

                fluid {
                  ...GatsbySanityImageFluid_withWebp_noBase64
                  src
                }
              }
            }
          }

          #

          ... on SanityGallerySection {
            _key
            _type

            backgroundColor {
              hex
              title
            }
          }

          #

          ... on SanityImageArticleSection {
            _type
            backgroundColor {
              hex
              title
            }
            fontColor {
              hex
              title
            }

            heading
            body

            image {
              altText

              asset {
                originalFilename
                title
                description

                fluid {
                  ...GatsbySanityImageFluid_withWebp_noBase64
                  src
                }
              }
            }

            imageAlign
            imageSize
          }

          #

          ... on SanityImageBannerSection {
            _type
            backgroundColor {
              hex
              title
            }

            image {
              altText

              asset {
                originalFilename
                title
                description

                fluid {
                  ...GatsbySanityImageFluid_withWebp_noBase64
                  src
                }
              }
            }

            spinningSVG {
              altText

              asset {
                originalFilename
                title
                description

                fluid {
                  ...GatsbySanityImageFluid_withWebp_noBase64
                  src
                }
              }
            }
          }

          #

          ... on SanityImageCarouselSection {
            _key
            _type
            backgroundColor {
              hex
              title
            }

            autoplay

            images {
              altText

              asset {
                originalFilename
                title
                description

                fluid {
                  ...GatsbySanityImageFluid_withWebp_noBase64
                  src
                }
              }
            }
          }

          #

          ... on SanityImageSection {
            _type
            backgroundColor {
              hex
              title
            }

            image {
              altText

              asset {
                originalFilename
                title
                description

                fluid {
                  ...GatsbySanityImageFluid_withWebp_noBase64
                  src
                }
              }
            }
          }

          #

          ... on SanityInstagramSection {
            _type
            backgroundColor {
              hex
              title
            }
            fontColor {
              hex
              title
            }

            button {
              buttonTarget
              buttonText
            }

            heading
            instagramPosts {
              image {
                altText

                asset {
                  originalFilename
                  title
                  description
                  label

                  fluid {
                    ...GatsbySanityImageFluid_withWebp_noBase64
                    src
                  }
                }
              }
              link
            }
          }

          #

          ... on SanityOpenTextSection {
            _type
            backgroundColor {
              hex
              title
            }
            fontColor {
              hex
              title
            }

            _rawBody
          }

          #

          ... on SanityProductCarouselSection {
            _type
            backgroundColor {
              hex
              title
            }
            fontColor {
              hex
              title
            }

            heading

            buttonLink {
              buttonTarget
              buttonText
            }

            products {
              id
              handle
              title
              description

              collections {
                title
              }

              image {
                altText

                asset {
                  originalFilename
                  title
                  description
                  label

                  fluid {
                    ...GatsbySanityImageFluid_withWebp_noBase64
                    src
                  }
                }
              }

              options {
                name

                values {
                  value
                }
              }

              sourceData {
                images {
                  edges {
                    node {
                      originalSrc
                    }
                  }
                }
              }

              variants {
                id
                title

                image {
                  altText

                  asset {
                    originalFilename
                    title
                    description
                    label

                    fluid {
                      ...GatsbySanityImageFluid_withWebp_noBase64
                      src
                    }
                  }
                }

                sourceData {
                  id
                  sku
                  title

                  priceV2 {
                    amount
                    currencyCode
                  }

                  selectedOptions {
                    name
                    value
                  }
                }
              }

              shopifyProduct {
                id
                handle
                title
                description

                images {
                  originalSrc
                }

                variants {
                  id
                  sku
                  title
                  price

                  image {
                    originalSrc
                  }

                  selectedOptions {
                    name
                    value
                  }

                  presentmentPrices {
                    edges {
                      node {
                        price {
                          amount
                          currencyCode
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          #

          ... on SanityTextSection {
            _type
            backgroundColor {
              hex
              title
            }

            body
            heading
            asH1

            button {
              buttonTarget
              buttonText
            }

            fontColor {
              hex
              title
            }
          }
        }
      }
    }
  }
`;
